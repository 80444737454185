<template>
  <div>
    <div v-if="situacaoBadgeInfo && isAdmin" class="line-styles mb-1">
      <div class="d-flex align-items-center h-100 text-nowrap table-responsive">
        <div class="d-flex flex-column ml-2 mt-2" style="text-decoration-color: black;">
          <h4>Situação:</h4>
        </div>
        <div class="d-flex flex-column ml-1 mr-1 justify-content-start align-items-start my-divider">
          <h5 class="">{{ this.company.situacao[0] }}</h5>
          <b-badge v-if="situacaoBadgeInfo" class="align-self-start" pill
            :class="`badge-light-${situacaoBadgeInfo.variant}
                     border-${situacaoBadgeInfo.variant}`">
            <span class="text-dark">{{ situacaoBadgeInfo.text }}</span>
          </b-badge>
        </div>
        <div class="d-flex flex-column mt-1">
          <b-button
            size="large"
            variant="outline-primary"
            class="btn-tour-skip mr-1 button-styles"
            @click="getCompanyDetails()"
          >
            <span class="align-middle">Detalhes</span>
          </b-button>
        </div>
        <div class="d-flex flex-column ml-2 mt-2 align-items-end justify-content-end w-100">
          <b-img src="@/assets/images/currency_icon.svg" class="justify-content-center mr-1" />
        </div>
        <span v-if="this.company.notas_comerciais_emitidas === 0" class="mr-2 mt-2">Nenhuma nota emitida</span>
        <span v-else class="mr-2 mt-2">{{ countNotasComerciais(this.company.notas_comerciais_emitidas) }}</span>
      </div>
    </div>

    <h3>Dados da empresa</h3>
    <validation-observer ref="loginForm" #default="{ invalid }">
      <b-row>
        <b-col sm="12" md="6" lg="3">
          <b-form-group label="CNPJ" label-for="registration-cnpj">
            <validation-provider #default="{ errors }" name="CNPJ" rules="required" vid="cnpj">
              <b-form-input
                id="registration-cnpj"
                v-model="company.cnpj"
                v-mask="'##.###.###/####-##'"
                :readonly="readonly"
                :state="errors.length > 0 ? false : null"
                name="registration-cnpj"
                type="text"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6" lg="3">
          <b-form-group label="Razão social" label-for="registration-Legalname">
            <validation-provider
              #default="{ errors }"
              name="Razão social"
              rules="required|max:100"
              vid="Legalname"
            >
              <b-form-input
                id="registration-Legalname"
                v-model="company.razao_social"
                :readonly="readonly"
                :state="errors.length > 0 ? false : null"
                name="registration-Legalname"
                :maxlength="100"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6" lg="3">
          <b-form-group label="Nome Fantasia" label-for="registration-fantasy-name">
            <validation-provider
              #default="{ errors }"
              name="Nome Fantasia"
              rules="required|max:100"
              vid="fantasy-name"
            >
              <b-form-input
                id="registration-fantasy-name"
                v-model="company.nome_fantasia"
                :state="errors.length > 0 ? false : null"
                name="registration-fantasy-name"
                :maxlength="100"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!-- Dados de contato -->
        <b-col sm="12" md="6" lg="3">
          <b-form-group label="Web site" label-for="registration-website">
            <validation-provider #default="{ errors }" name="Web site" vid="website" rules="max:50">
              <b-form-input
                id="registration-website"
                v-model="company.website"
                :state="errors.length > 0 ? false : null"
                name="registration-website"
                :maxlength="50"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>

      <h3>Endereço</h3>
      <b-row>
        <b-col sm="12" md="6" lg="4">
          <b-form-group label="CEP" label-for="registration-zipcode">
            <validation-provider #default="{ errors }" name="CEP" vid="zipcode">
              <b-form-input
                id="registration-zipcode"
                v-model="company.cep"
                v-mask="'#####-###'"
                @blur="getAddress"
                :state="errors.length > 0 ? false : null"
                name="registration-zipcode"
                type="text"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6" lg="4">
          <b-form-group label="Cidade" label-for="registration-city">
            <validation-provider #default="{ errors }" name="Cidade" rules="max:50" vid="city">
              <b-form-input
                id="registration-city"
                v-model="company.cidade"
                :maxlength="50"
                :state="errors.length > 0 ? false : null"
                name="registration-city"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6" lg="4">
          <b-form-group label="Estado" label-for="registration-state">
            <validation-provider #default="{ errors }" name="Estado" vid="state">
              <v-select
                id="registration-state"
                v-model="company.uf"
                :state="errors.length > 0 ? false : null"
                name="registration-state"
                :clearable="false"
                :options="ufOptions"
                :reduce="(value) => value.value"
                class="per-page-selector d-inline-block w-100"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6" lg="4">
          <b-form-group label="Bairro" label-for="registration-neighborhood">
            <validation-provider
              #default="{ errors }"
              name="Bairro"
              rules="max:100"
              vid="neighborhood"
            >
              <b-form-input
                id="registration-neighborhood"
                v-model="company.bairro"
                :maxlength="100"
                :state="errors.length > 0 ? false : null"
                name="registration-neighborhood"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6" lg="4">
          <b-form-group label="Logradouro" label-for="registration-street">
            <validation-provider
              #default="{ errors }"
              name="Logradouro"
              rules="max:250"
              vid="street"
            >
              <b-form-input
                id="registration-street"
                v-model="company.logradouro"
                :maxlength="250"
                :state="errors.length > 0 ? false : null"
                name="registration-street"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col sm="6" md="6" lg="2">
          <b-form-group label="Número" label-for="registration-number">
            <validation-provider #default="{ errors }" name="Número" rules="max:20" vid="number">
              <b-form-input
                id="registration-number"
                v-model="company.logradouro_numero"
                :maxlength="20"
                :state="errors.length > 0 ? false : null"
                name="registration-number"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col sm="6" md="6" lg="2">
          <b-form-group label="Complemento" label-for="registration-complement">
            <validation-provider
              #default="{ errors }"
              name="Complemento"
              vid="complement"
              rules="max:100"
            >
              <b-form-input
                id="registration-complement"
                v-model="company.complemento"
                :maxlength="100"
                :state="errors.length > 0 ? false : null"
                name="registration-complement"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>

      <div v-if="isAdmin">
        <h3>Rating</h3>
        <b-row>
          <b-col sm="12" md="6" lg="3">
            <b-form-group label="Classificação interna" label-for="internal-classification">
              <validation-provider
                #default="{ errors }"
                name="Classificação interna"
                rules="required"
                vid="internal-classification"
              >
                <v-select
                  id="internal-classification"
                  v-model="company.classificacao_interna"
                  :clearable="false"
                  :options="classificationTypes"
                  :reduce="(tipo) => tipo.value"
                  class="per-page-selector d-inline-block w-100"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <h3>Estatuto social da empresa</h3>

        <div
          class="d-flex justify-content-start align-items-end mb-2"
          v-if="company.contrato_social?.url"
        >
          <b-badge variant="light-primary" class="mt-1 mr-1 w-fit">
            <feather-icon icon="PaperclipIcon" size="16" />
            {{ extractNameFromS3Url(company.contrato_social?.url) }}
          </b-badge>
          <feather-icon
            icon="DownloadIcon"
            size="20"
            stroke="#093272"
            class="cursor-pointer"
            @click="downloadURI(company.contrato_social?.url)"
          />
        </div>

        <b-row>
          <b-col sm="12" md="6" lg="4">
            <span
              >Envie todas as folhas da última atualização do estatuto social da empresa,
              devidamente assinado.</span
            >
          </b-col>
          <b-col sm="12" md="6" lg="4">
            <b-form-group label="" label-for="company-social-contract">
              <validation-provider
                #default="{ errors }"
                name="estatuto-social"
                rules="required"
                vid="company-social-contract"
              >
                <cc-dropzone
                  v-model="company.contrato_social"
                  accept-formats=".pdf, .png, .jpg"
                  subtitle="'Envie seu arquivo nos formato .PDF, .PNG ou .JPG'"
                  fit
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </div>

      <div class="d-flex justify-content-end">
        <b-button :disabled="invalid" variant="primary" @click="complementData">{{
          saveButtonLabel
        }}</b-button>
      </div>
    </validation-observer>

    <b-modal id="show-details" centered hide-header hide-footer class="" size="lg">
      <div v-if="modalResult.nome" class="asd p-1">
        <div class="detail-header">
          <div class="row border-bottom align-items-center py-50">
            <div class="col-11 mb-1">
              <h3 class="mb-0 text-blue">
                <strong>Status da empresa</strong>
              </h3>
            </div>
            <div class="col-1 mb-1">
              <feather-icon
                :disabled="true"
                size="23"
                icon="XIcon"
                class="mr-75"
                style="cursor: pointer"
                :title="`Fechar modal`"
                @click="closeDetailsModal"
              />
            </div>
          </div>
          <div class="row justify-content-between mt-2 mb-1">
            <div v-if="modalResult.nome" class="col-6 d-flex">
              <h3>{{ modalResult.nome }}</h3>
            </div>
            <div class="col-6 d-flex justify-content-end">
              <span class="text-gray" style="font-size: 20px;">{{ countDays(modalResult.updated_at) }}</span>
            </div>
          </div>
        </div>
        <div class="border-top align-items-center justify-content-end py-50">
          <b-row>
            <b-col cols="4" class="text-nowrap">
              <h5 class="mb-0 text-black">Envio de convite</h5>
            </b-col>
            <b-col cols="4" class="d-flex justify-content-start">
              <span v-if="inviteBadge(modalResult).variant === 'not_applied'"> --- </span>
              <b-badge
                v-else
                pill
                :class="`badge-light-${inviteBadge(modalResult).variant} border-${
                  inviteBadge(modalResult).variant
                }`"
              >
                <span class="text-dark">{{ inviteBadge(modalResult).text }}</span>
              </b-badge>
            </b-col>
            <b-col cols="4" class="d-flex justify-content-end">
              <span v-if="inviteBadge(modalResult).variant === 'not_applied'"> --- </span>
              <p class="mb-0" v-else>{{ countDays(modalResult.envio_convite.data) }}</p>
            </b-col>
          </b-row>
        </div>
        <div class="border-top align-items-center py-50">
          <b-row>
            <b-col cols="4" class="text-nowrap">
              <h5 class="mb-0 text-black">Cadastro na plataforma</h5>
            </b-col>
            <b-col cols="4" class="d-flex justify-content-start">
              <span v-if="plataformRegistered(modalResult).variant === 'not_applied'"> --- </span>
              <b-badge
                v-else
                pill
                :class="`badge-light-${plataformRegistered(modalResult).variant} border-${
                  plataformRegistered(modalResult).variant
                }`"
              >
                <span class="text-dark">{{ plataformRegistered(modalResult).text }}</span>
              </b-badge>
            </b-col>
            <b-col cols="4" class="d-flex justify-content-end">
              <span v-if="plataformRegistered(modalResult).variant === 'not_applied'"> --- </span>
              <p class="mb-0" v-else>{{ countDays(modalResult.cadastro_plataforma.data) }}</p>
            </b-col>
          </b-row>
        </div>
        <div class="border-top align-items-center py-50">
          <b-row>
            <b-col cols="4" class="text-nowrap">
              <h5 class="mb-0 text-black">Documentação e SCR</h5>
            </b-col>
            <b-col cols="4" class="d-flex justify-content-start">
              <span v-if="documentSCRBadge(modalResult).variant === 'not_applied'"> --- </span>
              <b-badge
                v-else
                pill
                :class="`badge-light-${documentSCRBadge(modalResult).variant} border-${
                  documentSCRBadge(modalResult).variant
                }`"
              >
                <span class="text-dark">{{ documentSCRBadge(modalResult).text }}</span>
              </b-badge>
            </b-col>
            <b-col cols="4" class="d-flex justify-content-end">
              <span v-if="documentSCRBadge(modalResult).variant === 'not_applied'"> --- </span>
              <p class="mb-0" v-else>{{ countDays(modalResult.documentacao_scr.data) }}</p>
            </b-col>
          </b-row>
        </div>
        <div class="border-top align-items-center py-50">
          <b-row>
            <b-col cols="4" class="text-nowrap">
              <h5 class="mb-0 text-black">Análise de limite</h5>
            </b-col>
            <b-col cols="4" class="d-flex justify-content-start">
              <span v-if="aprovacaoLimiteBadge(modalResult).variant === 'not_applied'"> --- </span>
              <b-badge
                v-else
                pill
                :class="`badge-light-${aprovacaoLimiteBadge(modalResult).variant} border-${
                  aprovacaoLimiteBadge(modalResult).variant
                }`"
              >
                <span class="text-dark">{{ aprovacaoLimiteBadge(modalResult).text }}</span>
              </b-badge>
            </b-col>
            <b-col cols="4" class="d-flex justify-content-end">
              <span v-if="aprovacaoLimiteBadge(modalResult).variant === 'not_applied'"> --- </span>
              <p class="mb-0" v-else>{{ countDays(modalResult.analise_limite.data) }}</p>
            </b-col>
          </b-row>
        </div>
        <div class="border-top align-items-center py-50">
          <b-row>
            <b-col cols="4" class="text-nowrap">
              <h5 class="mb-0 text-black">Envio de documentação</h5>
            </b-col>
            <b-col cols="4" class="d-flex justify-content-start">
              <span v-if="envioDocumentacaoBadge(modalResult).variant === 'not_applied'">
                ---
              </span>
              <b-badge
                v-else
                pill
                :class="`badge-light-${envioDocumentacaoBadge(modalResult).variant} border-${
                  envioDocumentacaoBadge(modalResult).variant
                }`"
              >
                <span class="text-dark">{{ envioDocumentacaoBadge(modalResult).text }}</span>
              </b-badge>
            </b-col>
            <b-col cols="4" class="d-flex justify-content-end">
              <span v-if="envioDocumentacaoBadge(modalResult).variant === 'not_applied'">---</span>
              <p class="mb-0" v-else>{{ countDays(modalResult.envio_documentacao.data) }}</p>
            </b-col>
          </b-row>
        </div>
        <div class="border-top align-items-center py-50">
          <b-row>
            <b-col cols="4" class="text-nowrap">
              <h5 class="mb-0 text-black">Análise da documentação</h5>
            </b-col>
            <b-col cols="4" class="d-flex justify-content-start">
              <span v-if="analiseDocumentacaoBadge(modalResult).variant === 'not_applied'">
                ---
              </span>
              <b-badge
                v-else
                pill
                :class="`badge-light-${analiseDocumentacaoBadge(modalResult).variant} border-${
                  analiseDocumentacaoBadge(modalResult).variant
                }`"
              >
                <span class="text-dark">{{ analiseDocumentacaoBadge(modalResult).text }}</span>
              </b-badge>
            </b-col>
            <b-col cols="4" class="d-flex justify-content-end">
              <span v-if="analiseDocumentacaoBadge(modalResult).variant === 'not_applied'"
                >---</span
              >
              <p class="mb-0" v-else>{{ countDays(modalResult.analise_documentacao.data) }}</p>
            </b-col>
          </b-row>
        </div>
        <div class="border-top align-items-center py-50">
          <b-row>
            <b-col cols="4" class="text-nowrap">
              <h5 class="mb-0 text-black">Apto para operar</h5>
            </b-col>
            <b-col cols="4" class="d-flex justify-content-start">
              <span v-if="aptoOperarBadge(modalResult).variant === 'not_applied'"> --- </span>
              <b-badge
                v-else
                pill
                :class="`badge-light-${aptoOperarBadge(modalResult).variant} border-${
                  aptoOperarBadge(modalResult).variant
                }`"
              >
                <span class="text-dark">{{ aptoOperarBadge(modalResult).text }}</span>
              </b-badge>
            </b-col>
            <b-col cols="4" class="d-flex justify-content-end">
              <span v-if="aptoOperarBadge(modalResult).variant === 'not_applied'"> --- </span>
              <p class="mb-0" v-else>{{ countDays(modalResult.apto_operar.data) }}</p>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { email, required, max } from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BRow,
  BBadge,
  BImg,
  BModal,
} from 'bootstrap-vue'
import CcDropzone from '@core/components/cc-dropzone/CcDropzone.vue'
import vSelect from 'vue-select'
import { mask } from 'vue-the-mask'
import { brazilianStatesOptions } from '@core/mixins/ui/forms'
import axios from 'axios'
import { extractNameFromS3Url, downloadURI } from '@core/comp-functions/forms/cc-dropzone'

export default {
  name: 'CompanyForm',
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    CcDropzone,
    BFormCheckbox,
    BBadge,
    BModal,
    BImg,
  },
  directives: {
    mask,
  },
  mixins: [brazilianStatesOptions],
  data() {
    return {
      company: {
        razao_social: '',
        nome_fantasia: '',
        website: '',
        cnpj: '',
        cep: '',
        uf: '',
        cidade: '',
        logradouro: '',
        bairro: '',
        logradouro_numero: '',
        complemento: '',
        name: '',
        email: '',
        phone: '',
        cellphone: '',
        contrato_social: '',
        contato_email: '',
        contato_telefone: '',
        contato_celular: '',
        situacao: [],
      },
      required,
      email,
      max,
      classificationTypes: [
        { value: 'aaa', label: 'AAA' },
        { value: 'm', label: 'M' },
      ],
      readonly: false,
      modalResult: {},
    }
  },
  async mounted() {
    if (this.empresaId) {
      const { data } = await this.$store.dispatch('company/fetch', this.empresaId)
      this.company = data
      this.readonly = true
    }
  },
  methods: {
    extractNameFromS3Url,
    downloadURI,
    async complementData() {
      if (this.isAdmin) return this.saveOrUpdate()

      try {
        this.$swal.showLoading()
        await this.$store.dispatch('company/complementCompanyData', this.company)
        this.$swal.fire({
          title: 'Sucesso!',
          text: 'Dados atualizados com sucesso!',
          icon: 'success',
          confirmButtonText: 'Ok',
        })
        this.$emit('updated')
      } catch (error) {
        this.$swal.fire({
          title: 'Erro!',
          text: 'Não foi possível atualizar os dados!',
          icon: 'error',
          confirmButtonText: 'Ok',
        })
      }
    },
    async saveOrUpdate() {
      try {
        this.$swal.showLoading()
        const { data } = await this.$store.dispatch('dashboard/save', this.company)
        await this.$swal.fire({
          title: 'Sucesso!',
          text: 'Dados cadastrados com sucesso!',
          icon: 'success',
          confirmButtonText: 'Ok',
        })
        this.$router.push({
          name: 'admin-dashboard.general-data',
          params: { id: data.id },
        })
      } catch (error) {
        this.$swal.fire({
          title: 'Erro!',
          text: 'Não foi possível completar a operação!',
          icon: 'error',
          confirmButtonText: 'Ok',
        })
      }
    },
    async getAddress() {
      try {
        const response = await axios.get(`https://viacep.com.br/ws/${this.company.cep}/json/`)
        this.company.logradouro = response.data.logradouro
        this.company.bairro = response.data.bairro
        this.company.cidade = response.data.localidade
        this.company.uf = response.data.uf
      } catch (error) {
        console.error(error)
      }
    },
    async getCompanyDetails() {
      let params = {
        path: 'backoffice/relatorios_de_empresa',
        data: { empresa_id: this.company.id },
      }
      const { data } = await this.$store.dispatch('app/getIndexData', params)
      this.modalResult = data.data[0]
      this.$bvModal.show('show-details')
    },
    closeDetailsModal() {
      this.$bvModal.hide('show-details')
    },
    tipMethod(type, empresa) {
      switch (type) {
        case 'user_name':
          return `${empresa.user_name}</br>Clique para copiar`
        case 'email':
          return `${empresa.email}</br>Clique para copiar`
        case 'telefone':
          return `${empresa.telefone}</br>Clique para copiar`
        case 'celular':
          return `${empresa.celular}</br>Clique para copiar`
        default:
          return 'Clique para copiar'
      }
    },
    inviteBadge(item, isSituacaoBadge = null) {
      const badge = {
        aguardando_envio: {
          text: 'Aguardando envio',
          variant: 'warning',
        },
        convite_enviado: {
          text: 'Convite enviado',
          variant: 'success',
        },
        not_applied: {
          variant: 'not_applied',
          text: 'not_applied',
        },
      }

      if (isSituacaoBadge) return badge[item]
      return badge[item.envio_convite.status]
    },
    plataformRegistered(item, isSituacaoBadge = null) {
      const badge = {
        aguardando_cadastro: {
          text: 'Aguardando cadastro',
          variant: 'warning',
        },
        cadastro_realizado: {
          text: 'Cadastrado',
          variant: 'success',
        },
        not_applied: {
          variant: 'not_applied',
          text: 'not_applied',
        },
      }

      if (isSituacaoBadge) return badge[item]
      return badge[item.cadastro_plataforma.status]
    },
    documentSCRBadge(item, isSituacaoBadge = null) {
      const badge = {
        aguardando_envio: {
          text: 'Aguardando envio',
          variant: 'warning',
        },
        enviado_cliente: {
          text: 'Enviado pelo cliente',
          variant: 'success',
        },
        not_applied: {
          variant: 'not_applied',
          text: 'not_applied',
        },
      }

      if (isSituacaoBadge) return badge[item]
      return badge[item.documentacao_scr.status]
    },
    aprovacaoLimiteBadge(item, isSituacaoBadge = null) {
      const badge = {
        aprovado: {
          text: 'Aprovado',
          variant: 'success',
        },
        reprovado: {
          text: 'Reprovado',
          variant: 'danger',
        },
        aguardando: {
          variant: 'warning',
          text: 'Aguardando',
        },
        not_applied: {
          variant: 'not_applied',
          text: 'not_applied',
        },
      }

      if (isSituacaoBadge) return badge[item]
      return badge[item.analise_limite.status]
    },
    envioDocumentacaoBadge(item, isSituacaoBadge = null) {
      const badge = {
        aguardando_envio: {
          text: 'Aguardando envio',
          variant: 'warning',
        },
        documentacao_enviada: {
          text: 'Documentação enviada',
          variant: 'success',
        },
        not_applied: {
          variant: 'not_applied',
          text: 'not_applied',
        },
      }

      if (isSituacaoBadge) return badge[item]
      return badge[item.envio_documentacao.status]
    },
    analiseDocumentacaoBadge(item, isSituacaoBadge = null) {
      const badge = {
        aguardando: {
          text: 'Aguardando',
          variant: 'warning',
        },
        aprovado: {
          text: 'Aprovado',
          variant: 'success',
        },
        reprovada: {
          text: 'Reprovado',
          variant: 'danger',
        },
        not_applied: {
          variant: 'not_applied',
          text: 'not_applied',
        },
      }

      if (isSituacaoBadge) return badge[item]
      return badge[item.analise_documentacao.status]
    },
    aptoOperarBadge(item, isSituacaoBadge = null) {
      const badge = {
        sim: {
          text: 'Sim',
          variant: 'success',
        },
        nao: {
          text: 'Não',
          variant: 'danger',
        },
        not_applied: {
          variant: 'not_applied',
          text: 'not_applied',
        },
      }

      if (isSituacaoBadge) return badge[item]
      return badge[item.apto_operar.status]
    },
    countDays(date) {
      if (date === null) return '---'

      const data1 = new Date(date)
      data1.setHours(0, 0, 0, 0)
      const data2 = new Date()
      data2.setHours(0, 0, 0, 0)

      const milisecondsDiff = (data2 - data1)
      const milissegundosPorDia = 24 * 60 * 60 * 1000

      const diferencaEmDias = Math.round(milisecondsDiff / milissegundosPorDia)

      if (diferencaEmDias === 0) return 'Hoje'

      return diferencaEmDias > 1 ? `${diferencaEmDias} dias` : `${diferencaEmDias} dia`
    },
    situacaoBadge(situacao) {
      if (situacao[0] === 'Apto para operar') return this.aptoOperarBadge(situacao[1], true)
      if (situacao[0] === 'Análise de documentação') return this.analiseDocumentacaoBadge(situacao[1], true)
      if (situacao[0] === 'Envio de documentação') return this.envioDocumentacaoBadge(situacao[1], true)
      if (situacao[0] === 'Análise de limite') return this.aprovacaoLimiteBadge(situacao[1], true)
      if (situacao[0] === 'Documentação e SCR') return this.documentSCRBadge(situacao[1], true)
      if (situacao[0] === 'Cadastro na plataforma') return this.plataformRegistered(situacao[1], true)
      if (situacao[0] === 'Envio de convite') return this.inviteBadge(situacao[1], true)
    },
    countNotasComerciais(qtdNotas) {
      return qtdNotas === 1 ? `${qtdNotas} nota emitida` : `${qtdNotas} notas emitidas`
    }
  },
  computed: {
    situacaoBadgeInfo() {
      return this.situacaoBadge(this.company.situacao)
    },
    empresaId() {
      return this.$store.state.auth.userData.user_type === 'admin'
        ? this.$route.params.id
        : this.$store.state.auth.userData.empresa_id
    },
    saveButtonLabel() {
      return this.empresaId ? 'Salvar alterações' : 'Continuar'
    },
    isAdmin() {
      return this.$store.state.auth.userData.user_type === 'admin'
    },
  },
}
</script>

<style lang="scss">
.line-styles {
  border: 1px solid #dae1e8;
  border-radius: 6px;
  background-color: #F8FAFC;
}.button-styles {
  background-color: #F8FAFC;
}
.my-divider {
  position: relative;
  margin: 1rem 0 0rem 1rem;
  padding: 0 1rem;
}
.my-divider::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 1px;
  background: #becad4;
}
</style>
